import { settings } from "configs/settings";
import ExpandableMenu from "layout/drawer/ExpandableMenu";
import LinkMenuItem from "layout/drawer/LinkMenuItem";
import NestedMenuItem from "layout/drawer/NestedMenuItem";
import { useRouter } from "next/router";
import { type OpMenu, type OpMenuItem } from "op-menu";

interface Props {
  opMenuList: OpMenu[];
}

function MenuListItems({ opMenuList }: Props) {
  const host = settings.ORIGIN_VUE_OPS;
  const router = useRouter();

  const isSamePath = (opMenuItem: OpMenuItem) => {
    const { path, isMigrated } = opMenuItem;
    const urlWithoutQuery = path.split("/?")[0];

    return router.pathname === urlWithoutQuery && isMigrated === true;
  };

  return (
    <>
      {opMenuList.map((opMenuItem) => {
        if (opMenuItem.type === "menu") {
          return (
            <LinkMenuItem
              title={opMenuItem.title}
              typhographySxProps={{ fontWeight: 600 }}
              icon={<i className={`fa-solid fa-lg ${opMenuItem.icon}`}></i>}
              key={opMenuItem.title}
              to={
                opMenuItem.isMigrated === false
                  ? `${host}${opMenuItem.path}`
                  : (opMenuItem.path ?? "")
              }
              isSelected={isSamePath(opMenuItem)}
            />
          );
        }

        return (
          <ExpandableMenu
            key={opMenuItem.title}
            title={opMenuItem.title}
            icon={<i className={`fa-solid fa-lg ${opMenuItem.icon}`}></i>}
            defaultExpanded={true}
            sx={{ bgcolor: "action.hover" }}
            typhographySxProps={{ fontWeight: 600 }}
          >
            {opMenuItem.children.map((menuItem) => {
              if (menuItem.type === "menu") {
                return (
                  <LinkMenuItem
                    key={menuItem.title}
                    title={menuItem.title}
                    icon={<i className={`fa-solid fa-lg ${menuItem.icon}`}></i>}
                    to={
                      menuItem.isMigrated === false
                        ? `${host}${menuItem.path}`
                        : (menuItem.path ?? "")
                    }
                    isSelected={isSamePath(menuItem)}
                  />
                );
              }
              return (
                <ExpandableMenu
                  key={menuItem.title}
                  title={menuItem.title}
                  icon={<i className={`fa-solid fa-lg ${menuItem.icon}`}></i>}
                  defaultExpanded={menuItem.children
                    .map((item: OpMenuItem) => item.path)
                    .includes(router.pathname)}
                >
                  {menuItem.children.map((item: OpMenu & { type: "menu" }) => (
                    <NestedMenuItem
                      key={item.title}
                      title={item.title}
                      icon={<i className={`fa-solid ${item.icon}`}></i>}
                      to={
                        item.isMigrated === false
                          ? `${host}${item.path}`
                          : (item.path ?? "")
                      }
                      isSelected={isSamePath(item)}
                    />
                  ))}
                </ExpandableMenu>
              );
            })}
          </ExpandableMenu>
        );
      })}
    </>
  );
}

export default MenuListItems;
