import useAccountInfoQuery from "./queries/useAccountInfoQuery";

const useAccountInfo = () => {
  const { accountInfo } = useAccountInfoQuery({
    gcTime: Infinity,
    staleTime: Infinity
  });

  return accountInfo;
};

export default useAccountInfo;
