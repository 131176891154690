import { useRouter } from "next/router";
import { useEffect } from "react";

export default function index() {
  const router = useRouter();

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    router.replace("/dashboard");
  }, []);

  return null;
}
