import { atom } from "recoil";
import type { AppLayout } from "types/appLayout.type";

export const AppLayoutState = atom<AppLayout>({
  default: {
    isLayoutHidden: false,
    isShowPage: false
  },
  key: "AppLayoutState"
});
