import { setDatadogStaff } from "datadog";
import useAppLayoutState from "hooks/recoilState/useAppLayoutState";
import useAccountInfo from "hooks/useAccountInfo";
import { useRouter } from "next/router";
import { canAccessPage, filterOpMenuListByRole, opMenuPathList } from "op-menu";
import { type ReactNode, useEffect } from "react";
import { normalizePath } from "shared-utils";

interface Props {
  children: ReactNode;
}

const AuthProvider = ({ children }: Props) => {
  const router = useRouter();
  const accountInfo = useAccountInfo();
  const [, setAppLayoutState] = useAppLayoutState();

  useEffect(() => {
    setDatadogStaff({
      name: accountInfo.name,
      roles: accountInfo.roles
    });
  }, [accountInfo]);

  useEffect(() => {
    const validatePageAccess = () => {
      const filteredOpMenuList = filterOpMenuListByRole(accountInfo.roles);
      const hasAccess = canAccessPage(router.pathname, filteredOpMenuList);

      if (!hasAccess) {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        router.replace("/permission");
        return;
      }

      setAppLayoutState({ isShowPage: true });
    };

    const normalizedPath = normalizePath(router.pathname);
    if (!opMenuPathList.includes(normalizedPath)) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      router.replace("/404");
      setAppLayoutState({ isShowPage: true });
      return;
    }

    validatePageAccess();
  }, [router.pathname]);

  return <>{children}</>;
};

export default AuthProvider;
