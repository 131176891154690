import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import { logout } from "configs/keycloak";
import { removeAllCookies } from "utils/cookie";

function LogoutButton() {
  const handleLogout = () => {
    logout();
    removeAllCookies();
  };

  return (
    <ListItem disablePadding>
      <ListItemButton dense onClick={handleLogout}>
        <ListItemText>
          <Typography sx={{ fontSize: 13 }}>로그아웃</Typography>
        </ListItemText>
      </ListItemButton>
    </ListItem>
  );
}

export default LogoutButton;
