import Keycloak, { type KeycloakError } from "keycloak-js";
import type { Role } from "op-menu";
import { settings } from "./settings";

let keycloak: Keycloak | null = null;
let isAuthenticated = false;

export const keycloakInit = async () => {
  try {
    keycloak = new Keycloak({
      url: settings.AUTH_API_URL,
      realm: "socar",
      clientId: "socar-backoffice-portal"
    });

    keycloak.onAuthError = ({ error, error_description }: KeycloakError) => {
      console.error("Failed to initialize keycloak", error, error_description);
    };

    isAuthenticated = await keycloak.init({
      onLoad: "login-required",
      checkLoginIframe: false
    });
  } catch (e) {
    if (e instanceof Error) {
      console.error(e.message);
    } else {
      console.error("Unexpected error is occurred.");
    }
  }
};

const getValidKeycloak = () => {
  if (!keycloak) throw new Error("keycloak is not initialized yet");
  return keycloak;
};

export const getToken = () => getValidKeycloak().token;
export const getUserInfo = () => getValidKeycloak().tokenParsed;
export const getRoles = (): Role[] | undefined =>
  getUserInfo().resource_access[settings.CLINET_ID]?.roles as
    | Role[]
    | undefined;
export const logout = () => getValidKeycloak().logout();
export const getIsAuthenticated = () => isAuthenticated;
