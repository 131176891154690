type Settings = {
  ORIGIN_VUE_OPS: string;
  ORIGIN_REACT_OPS: string;
  API_DOMAIN: string;
  CDN_DOMAIN: string;
  VEHICLE_API_DOMAIN: string;
  MODE?: string;
  AUTH_API_URL: string;
  CLINET_ID: string;
};

const ENV_MODE = process.env.NEXT_PUBLIC_MODE;

const isLocal =
  ENV_MODE === "development" && process.env.NEXT_PUBLIC_LOCAL_MODE === "true";

const devDefaultSettings: Settings = {
  ORIGIN_VUE_OPS: "https://ops-dev.elecle.me",
  ORIGIN_REACT_OPS: "https://op2-dev.elecle.me",
  API_DOMAIN: "https://backoffice-gateway.socar.me/elecle-test-1",
  CDN_DOMAIN: "https://d12plrb6tqz2pw.cloudfront.net/assets",
  VEHICLE_API_DOMAIN: "https://backoffice-gateway.socar.me/elecle-test-2",
  AUTH_API_URL: "https://keycloak.socar.me/auth",
  CLINET_ID: "socar-elecle-dev",
  MODE: "development"
};

const localDefaultSettings: Settings = {
  ...devDefaultSettings,
  MODE: "local",
  ORIGIN_VUE_OPS: "http://localhost:8099",
  ORIGIN_REACT_OPS: "http://localhost:3000"
};

const prodDefaultSettings: Settings = {
  ORIGIN_VUE_OPS: "https://ops.elecle.bike",
  ORIGIN_REACT_OPS: "https://op2.elecle.bike",
  API_DOMAIN: "https://backoffice-gateway.socarcorp.co.kr/elecle",
  CDN_DOMAIN: "https://cdn.elecle.bike/assets",
  VEHICLE_API_DOMAIN:
    "https://backoffice-gateway.socarcorp.co.kr/elecle-landmarker",
  AUTH_API_URL: "https://idp.socarcorp.co.kr/auth",
  CLINET_ID: "elecle-admin",
  MODE: "production"
};

const stagingDefaultSettings: Settings = {
  ...prodDefaultSettings,
  ORIGIN_VUE_OPS: "https://ops-staging.elecle.bike",
  ORIGIN_REACT_OPS: "https://op2-staging.elecle.bike",
  API_DOMAIN: "https://backoffice-gateway.socarcorp.co.kr/elecle-staging",
  VEHICLE_API_DOMAIN:
    "https://backoffice-gateway.socarcorp.co.kr/elecle-landmarker-staging",
  CLINET_ID: "elecle-admin-staging",
  MODE: "staging"
};

const environmentSettings: Record<string, Settings> = {
  development: devDefaultSettings,
  localDevelopment: localDefaultSettings,
  production: prodDefaultSettings,
  staging: stagingDefaultSettings
};

export const settings: Partial<Settings> = {
  ...(ENV_MODE === "production" && environmentSettings.production),
  ...(ENV_MODE === "staging" && environmentSettings.staging),
  ...(ENV_MODE === "development" && environmentSettings.development),
  ...(isLocal && environmentSettings.localDevelopment)
};
