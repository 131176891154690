import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import useAccountInfo from "hooks/useAccountInfo";
import MenuItem from "layout/drawer/MenuItem";
import { useMemo } from "react";

function UserInfoItem() {
  const accountInfo = useAccountInfo();

  const userInfoTitle = useMemo(
    () => (accountInfo ? `${accountInfo.name}` : null),
    [accountInfo]
  );

  return <MenuItem icon={<AccountCircleIcon />} title={userInfoTitle} />;
}

export default UserInfoItem;
