import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import useAccountInfo from "hooks/useAccountInfo";
import MenuListItems from "layout/drawer/MenuListItems";
import LogoutButton from "layout/LogoutButton";
import UserInfoItem from "layout/UserInfoItem";
import { filterOpMenuListByRole } from "op-menu";

function MenuList() {
  const accountInfo = useAccountInfo();
  const filteredOpMenuList = filterOpMenuListByRole(accountInfo.roles);

  return (
    <List>
      <MenuListItems opMenuList={filteredOpMenuList} />
      <Divider sx={{ paddingY: 1 }} />
      <Box sx={{ paddingTop: 2 }}>
        <UserInfoItem />
        <LogoutButton />
      </Box>
    </List>
  );
}

export default MenuList;
