import { queryOptions, useSuspenseQuery } from "@tanstack/react-query";
import { getAccountInfo } from "network/account/accountApi";
import type { Account } from "types/account.type";
import type { CustomSuspenseQueryOptions } from "types/reacatQuery.type";

export type AccountInfoQueryOptions = CustomSuspenseQueryOptions<Account>;

export const accountInfoQueryOptions = (options?: AccountInfoQueryOptions) =>
  queryOptions({
    queryKey: ["accountInfo"],
    queryFn: getAccountInfo,
    ...options
  });
const useAccountInfoQuery = (options?: AccountInfoQueryOptions) => {
  const { data, error, isFetching } = useSuspenseQuery(
    accountInfoQueryOptions(options)
  );

  return {
    accountInfo: data,
    accountInfoError: error,
    accountInfoFetching: isFetching
  };
};

export default useAccountInfoQuery;
