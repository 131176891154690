import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/system/Stack";

export default function Loading() {
  return (
    <Stack height="90vh" alignItems="center" justifyContent="center">
      <CircularProgress />
    </Stack>
  );
}
