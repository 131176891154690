import type { AxiosRequestConfig } from "axios";
import { getAxiosInstance } from "./axios";

export function getFetcher<T = unknown>(
  url: string,
  config?: AxiosRequestConfig<any>
) {
  return getAxiosInstance()
    .get<T>(url, config)
    .then((res) => res.data);
}

export function putFetcher<T = unknown>(
  url: string,
  data?: Record<string, any>,
  config?: AxiosRequestConfig<any>
) {
  return getAxiosInstance()
    .put<T>(url, data, config)
    .then((res) => res.data);
}

export function patchFetcher<T = unknown>(
  url: string,
  data?: Record<string, any>,
  config?: AxiosRequestConfig<any>
) {
  return getAxiosInstance()
    .patch<T>(url, data, config)
    .then((res) => res.data);
}

export function postFetcher<T = unknown>(
  url: string,
  data?: Record<string, any>,
  config?: AxiosRequestConfig<any>
) {
  return getAxiosInstance()
    .post<T>(url, data, config)
    .then((res) => res.data);
}

export function deleteFetcher<T>(
  url: string,
  config?: AxiosRequestConfig<any>
) {
  return getAxiosInstance()
    .delete<T>(url, config)
    .then((res) => res.data);
}
