import axios, { type AxiosInstance, type RawAxiosRequestHeaders } from "axios";
import { removeEmptyValues } from "shared-utils";
import { getRoles, getToken, getUserInfo } from "./keycloak";
import { settings } from "./settings";

let instance: AxiosInstance | null = null;

const getAuthHeaders = (token?: string) => {
  const headers: RawAxiosRequestHeaders = {};
  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }

  return headers;
};

export const axiosInit = () => {
  const token = getToken();
  const userInfo = getUserInfo();
  const roles = getRoles();

  const headers = Object.assign(getAuthHeaders(token), {
    "X-Requested-With": "XMLHttpRequest",
    "X-Keycloak-Email": encodeURIComponent(userInfo.email),
    "X-Keycloak-Name": encodeURIComponent(userInfo.name),
    "X-Keycloak-Roles": encodeURIComponent(roles?.join(", ")),
    "X-Keycloak-Nickname": userInfo.nickname
      ? encodeURIComponent(userInfo.nickname)
      : null
  });

  instance = axios.create({
    baseURL: settings.API_DOMAIN,
    headers: removeEmptyValues(headers)
  });

  instance.interceptors.request.use(
    async (config) => {
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
};

export default axiosInit;

export const getAxiosInstance = () => {
  if (!instance) {
    throw new Error("axios is not initialized yet");
  }

  return instance;
};
