import useAppLayoutState from "hooks/recoilState/useAppLayoutState";
import Layout from "layout";
import type { AppProps } from "next/app";
import { useRouter } from "next/router";
import { opMenuListExcludedPageList } from "op-menu";
import { AsyncErrorBoundary } from "provider/AsyncErrorBoundary";
import { type ReactNode, useEffect } from "react";

interface Props {
  children: ReactNode;
  Component: AppProps["Component"];
  pageProps: AppProps["pageProps"];
}

export const LayoutContainer = ({ Component, pageProps, children }: Props) => {
  const router = useRouter();
  const pathWithoutLayout = opMenuListExcludedPageList;
  const [appLayoutState, setAppLayoutState] = useAppLayoutState();

  useEffect(() => {
    if (router.isReady && pathWithoutLayout.includes(router.pathname)) {
      setAppLayoutState({
        isLayoutHidden: true
      });
    }
  }, [router.isReady]);

  const RenderPage = () =>
    appLayoutState.isShowPage ? <Component {...pageProps} /> : null;

  return (
    <>
      {appLayoutState.isLayoutHidden ? (
        <AsyncErrorBoundary>
          <RenderPage />
        </AsyncErrorBoundary>
      ) : (
        <Layout>
          <AsyncErrorBoundary>
            <RenderPage />
          </AsyncErrorBoundary>
        </Layout>
      )}
      {children}
    </>
  );
};
