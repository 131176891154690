import Typography from "@mui/material/Typography";
import Stack from "@mui/system/Stack";

export default function Permission() {
  return (
    <Stack
      height="90vh"
      alignItems="center"
      justifyContent="center"
      textAlign="center"
    >
      <Typography>
        일레클 어드민 사용 권한이 없습니다.
        <br />
        옥스트라 및 키클락 계정 권한 신청서를 통해 신청해 주세요.
      </Typography>
    </Stack>
  );
}
